import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export function useAnalytics() {
    const store = useSelector((state) => state.interface.store);

    function formatProduct(source, product, quantity) {
        if(source === 'google') {
            product = {
                id: product.sku,
                name: product.name,
                quantity,
                price: product.price
            };
        }
        if(source === 'facebook') {
            product = {
                content_name: product.name,
                content_ids: [product.sku],
                content_type: 'product',
                value: product.price,
                currency: 'BRL'
            }
        }
        return product;
    }

    function trackGoogleAnalytics(...args) {
        //console.log('google',...args);
        
        if (process.env.NODE_ENV !== 'production') {
            return;
        }

        if (!window.gtag) {
            setTimeout(() => {
                trackGoogleAnalytics(...args);
            }, 500);
            return;
        }

        window.gtag(...args);
    }

    function trackFacebookPixel(...args) {
        //console.log('facebook',...args);

        if (process.env.NODE_ENV !== 'production') {
            return;
        }

        if(!window.fbq) {
            setTimeout(() => {
                trackFacebookPixel(...args);
            }, 500);
            return;
        }
        window.fbq(...args);
    }

    function trackPageview(title, path) {
        trackGoogleAnalytics('event', 'page_view', {
            "page_title": title,
            "page_path": path,
        });
        trackFacebookPixel('track', 'PageView');
    }

    function trackCartAction(action, product, quantity) {

        if(action == 'add_to_cart') {
            trackGoogleAnalytics('event', 'add_to_cart', {
                "items": [formatProduct('google', product, quantity)]
            });
            trackFacebookPixel('track', 'AddToCart', formatProduct('facebook', product, quantity))
        }

        if(action == 'remove_from_cart') {
            trackGoogleAnalytics('event', 'remove_from_cart', {
                "items": [formatProduct('google', product, quantity)]
            });
        }
    }

    function trackPurchase(order) {
        trackGoogleAnalytics('event', 'purchase', {
            "transaction_id": order.id,
            "value": order.totalPrice,
            "currency": "BRL",
            "shipping": order.totalShipping,
            "items": order.items.map(item=>(formatProduct('google', item, item.quantity)))
        });
        trackFacebookPixel('track', 'Purchase', {
            "currency": "BRL",
            "value": order.totalPrice,
        });
    }

    function trackProductView(product) {
        trackGoogleAnalytics('event', 'view_item', {
            "items": [formatProduct('google', product, 1)]
        });
        trackFacebookPixel('track', 'ViewContent', formatProduct('facebook', product, 1));
    }

    function trackEvent(action, category, label) {
        trackGoogleAnalytics('event', action, {
            'event_category': category,
            'event_label': label,
        });
    }

    function trackEventForm(action, category, label) {
        window.dataLayer.push({
            'event': action, 
            'eventModel': {
                'event_category': category,
            },
            'gtm': {
                'formSubmit': label,
                'elementId': label,
            }
        });
    }

    /*function getRefQueryParam(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window?.location?.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

     // utm persist
     let history = useHistory();   
     if (history.location.search) {
         sessionStorage.setItem('savedSearch', history.location.search);
     } else {
        let savedSearch = sessionStorage.getItem('savedSearch');
         if (savedSearch) {
             history.replace(history.location.pathname + savedSearch);
         }
     }*/

    function getRefQueryParam(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window?.location?.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    
    function removeQueryParam(url, param) {
        const urlParts = url.split('?');
        if (urlParts.length < 2) return url;
    
        const baseUrl = urlParts[0];
        const queryParams = urlParts[1]
            .split('&')
            .filter(p => !p.startsWith(`${param}=`)) // Remove o parâmetro
            .join('&');
    
        return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
    }
    
    // Verifica se precisa redirecionar
    function redirectIfNamecodeExists() {
        const currentUrl = window.location.href;
        const cleanUrl = removeQueryParam(currentUrl, 'namecode');
    
        if (currentUrl !== cleanUrl) {
            window.location.href = cleanUrl; // Redireciona para a URL limpa
        }
    }
    
    // utm persist
    let history = useHistory();
    if (history.location.search) {
        const cleanSearch = removeQueryParam(history.location.search, 'namecode');
        sessionStorage.setItem('savedSearch', cleanSearch);
    } else {
        let savedSearch = sessionStorage.getItem('savedSearch');
        if (savedSearch) {
            history.replace(history.location.pathname + savedSearch);
        }
    }
    
    // Chama a função para redirecionar caso o parâmetro "namecode" esteja presente
    //redirectIfNamecodeExists();

    return { trackPageview, trackCartAction, trackPurchase, trackProductView, trackEvent, trackEventForm, getRefQueryParam };
}
